@media screen and (min-width: 1441px) {
  .ForBigScreen {
    padding: 0 20% 0 20%;
  }
}

.HomeLogo {
  max-width: 110px;
  position: fixed;
  width: 20%;
  right: 50%;
  left: 50%;
  transform: translateX(-50%);
  filter: drop-shadow(2px 3px 5px rgba(0, 0, 0, 0.2));
  z-index: 99999;
}

.Title {
  position: relative;
  margin-bottom: 10%;
}

.TitleDeco {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 991px) {
  .TitleDeco {
    padding: 20%;
  }
}

@media screen and (max-width: 576px) {
  .TitleDeco {
    margin-top: 10%;
  }
}

.TitleHalfCircle {
  position: relative;
  left: 25%;
  transform: translateY(-7%) translateX(-50%);
}

.HeadMain {
  font-size: 220%;
  font-weight: 100;
  letter-spacing: 1vw;
}

.restrict-width {
  max-width: 1200px;
  margin: 0 auto;
}

.Left {
  max-width: 600px;
}

.Right {
  position: relative;
  max-width: 600px;
}

.Scroll {
  position: absolute;
  bottom: 15%;
  right: 0;
  max-width: 18px;
  transform: translateX(-50%);
  -webkit-animation: blink 1.5s ease-in-out infinite alternate;
  -moz-animation: blink 1.5s ease-in-out infinite alternate;
  animation: blink 1.5s ease-in-out infinite alternate;
}
@-webkit-keyframes blink {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes blink {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blink {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

.TakeoutTop {
  width: 50%;
  min-width: 125px;
  max-width: 250px;
  padding: 0 15px 0 15px;
  background-color: #007bff;
  margin: 30%;
  display: flex;
  filter: drop-shadow(2px 3px 5px rgba(0, 0, 0, 0.2));
  position: absolute;
  bottom: 5rem;
  left: 0;
  right: 0;
  text-align: center;
  border-radius: 50px;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.2));
}

.TakeoutTop:hover {
  transform: scale(0.95);
  transition: transform 0.5s cubic-bezier(0.25, 1, 0.5, 1);
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.2));
}

.TakeoutText {
  margin: 10% auto;
  font-family: "Noto sans JP", serif;
  color: #fff;
}

.HanamiTop {
  width: 50%;
  min-width: 125px;
  max-width: 250px;
  padding: 0 15px 0 15px;
  background-color: #fcbfb9;
  margin: 30%;
  display: flex;
  filter: drop-shadow(2px 3px 5px rgba(0, 0, 0, 0.2));
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  border-radius: 50px;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.2));
}

.HanamiTop:hover {
  transform: scale(0.95);
  transition: transform 0.5s cubic-bezier(0.25, 1, 0.5, 1);
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.2));
}

.HanamiText {
  margin: 10% auto;
  font-family: "Noto sans JP", serif;
  color: #fff;
}

/* for Mobile */
.MobileButtonsTitle {
  margin-top: 3rem !important;
  font-size: 14px;
  color: white;
  text-align: center;
}

.MobileTakeout {
  color: #fff;
  background-color: #007bff;
  margin: 0 0.5rem 0 1rem;
  text-align: center;
  padding-top: 1rem;
  border-radius: 10px;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.2));
}

.TakeoutTextM {
  font-size: 100%;
  padding-top: 10px;
  font-family: "Noto sans JP", serif;
  color: #fff;
}

.MobileHanami {
  color: #fff;
  background-color: #fcbfb9;
  margin: 0 1rem 0 0.5rem;
  text-align: center;
  padding-top: 1rem;
  border-radius: 10px;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.2));
}

.HanamiTextM {
  font-size: 100%;
  padding-top: 10px;
  font-family: "Noto sans JP", serif;
  color: #fff;
}

.CarouselBg {
  background-color: #fff;
  width: 100%;
  padding: 5% 10%;
  border-radius: 25px;
  /* filter: drop-shadow(2px 3px 5px rgba(0, 0, 0, 0.2)); */
}

.carousel-control-prev {
  filter: drop-shadow(2px 3px 5px rgba(33, 33, 33, 0.5));
  opacity: 1;
}

/* .carousel-control-prev-icon {
  width: 30px;
  height: 30px;
} */

.carousel-control-next {
  filter: drop-shadow(2px 3px 5px rgba(33, 33, 33, 0.5));
  opacity: 1;
}

/* .carousel-control-next-icon {
  width: 30px;
  height: 30px;
} */

@media screen and (min-width: 767px) {
  .CarouselPic {
    margin: 0 auto;
    /* max-width: 400px; */
  }
}

.CarouselCap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.CarouselText {
  font-family: "Noto Serif JP", serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.2vw;
  line-height: 2;
  color: #c73d13;
  writing-mode: vertical-rl;
  align-items: center;
  margin-top: 1rem;
}

.IntroSec {
  font-family: "Noto Serif JP", serif;
  color: #f3f0db;
}

.IntroSecTop {
  margin-top: 15%;
  font-family: "Noto Serif JP", serif;
  color: #f3f0db;
}

.IntroSecBottom {
  margin-bottom: 15%;
  font-family: "Noto Serif JP", serif;
  color: #f3f0db;

  @media screen and (max-width: 576px) {
    margin-bottom: 0;
  }
}

.IntroInsideSec {
  margin: 5% 0 5% 0;
  /* filter: drop-shadow(2px 3px 5px rgba(0, 0, 0, 0.2)); */
}

.IntroCaption {
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
}

.IntroCaption2 {
  padding: 12% 0 7% 0;
}

.IntroCaptionTitle1 {
  font-size: 32px;
}

.IntroCaptionTitle2 {
  font-size: 32px;
  padding-bottom: 3%;
}

/* .br_TopPage {
  white-space: pre-wrap;
  font-family: "Noto Serif JP", serif;
  font-size: 32px;
  color: #f3f0db;
  padding-bottom: 3%;
} */

.IntroCaptionP {
  font-size: 14px;
}

.IntroPic {
  display: block;
  height: auto;
  max-width: 100%;
  margin: 0 auto;
  /* filter: drop-shadow(2px 3px 5px rgba(0, 0, 0, 0.2)); */
}

/* @media screen and (min-width: 767px) {
  .IntroPic {
    max-width: 400px;
  }
} */

.NewsBg {
  background-color: #fff;
  padding: 2% 5% 5%;
  border: solid 8px #007bff;
  border-radius: 30px;

  @media screen and (max-width: 991px) {
    border: solid 4px #007bff;
  }

  @media screen and (max-width: 576px) {
    border-radius: 6.6vw;
    padding: 0;
    border: none;
    background-color: transparent;
  }
}

.NewsTitle {
  margin-bottom: 1%;
  color: #007bff;
  font-weight: bold;
  letter-spacing: 0.2em;
  text-align: center;

  @media screen and (max-width: 576px) {
    color: #fff;
  }
}

.NewsTitleFlashing {
  color: #007bff;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  -webkit-animation: blink 1.5s ease-in-out infinite alternate;
  -moz-animation: blink 1.5s ease-in-out infinite alternate;
  animation: blink 1.5s ease-in-out infinite alternate;
}
@-webkit-keyframes blink {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes blink {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blink {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

.list-group {
  filter: drop-shadow(2px 3px 5px rgba(0, 0, 0, 0.2));
}

.Point {
  text-align: center;
  margin: 20% 0 5% 0;
}

.PointDeco {
  padding: 20%;
}

.PointBtn {
  width: 100%;
  max-width: 400px;
  filter: drop-shadow(2px 3px 5px rgba(0, 0, 0, 0.2));
  margin-left: 30px;
  border: 1px solid white;
  border-radius: 21px;
}

@media screen and (max-width: 1260px) {
  .PointBtn {
    border-radius: 1.6vw;
  }
}

@media screen and (max-width: 767px) {
  .PointBtn {
    border-radius: 3vw;
  }
}

@media screen and (max-width: 690px) {
  .PointBtn {
    margin-left: 5.2vw;
  }
}

.PointCardText {
  font-size: 32px;
  font-family: "Noto Serif JP", serif;
  font-weight: 600;
  letter-spacing: 0.5vw;
  line-height: 2;
  color: #fff;
  writing-mode: vertical-rl;
  align-items: center;
}

@media screen and (max-width: 690px) {
  .PointCardText {
    font-size: 5.6vw;
    line-height: 1.5;
  }
}

.Takeout {
  text-align: center;
  margin: 5% 0 20% 15px;
  /* filter: drop-shadow(2px 3px 5px rgba(0, 0, 0, 0.2)); */
}

.TakeoutBtn {
  max-width: 600px;
  border-radius: 2rem;
  filter: drop-shadow(2px 3px 5px rgba(0, 0, 0, 0.2));
}

@media screen and (max-width: 576px) {
  .TakeoutBtn {
    border-radius: 0.5rem;
  }
}

.TakeoutBtn:hover {
  transform: scale(0.95);
  transition: transform 0.5s cubic-bezier(0.25, 1, 0.5, 1);
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.2));
}

.CardList {
  margin: 3% 0 3% 0;
  text-align: center;
  filter: drop-shadow(2px 3px 5px rgba(0, 0, 0, 0.2));
}

.Contact {
  margin: 8% 0 13% 0;
  text-align: center;
  filter: drop-shadow(2px 3px 5px rgba(0, 0, 0, 0.2));
}

.Copylight {
  margin: 15% 0 5% 0;
  text-align: center;
}

.CopylightIcon {
  width: 65%;
}

.Hanami {
  width: 100%;
  height: 100vh;
  background-color: yellow;
}

.card-container {
  max-width: 563px;
  margin: 0 auto;
  color: #fff;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 60px;

  ul,
  ol {
    padding-inline-start: 0;
    list-style-position: inside;
  }

  ul {
    list-style: none;
  }
  li {
    position: relative;
    padding-left: 16px;
  }
  li:before {
    content: "•";
    left: 0;
    position: absolute;
  }
}

.takeout-container {
  min-width: 563px;
  margin: 0 auto;
  color: #fff;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 60px;
  transition: 0.2s cubic-bezier(0.65, 0, 0.16, 1);
}

#takeout-container:hover {
  transition: 0.2s cubic-bezier(0.65, 0, 0.16, 1);
  background-color: #007bff;
  border-radius: 10px;
}

.against-reboot {
  margin-top: 1rem;
}

.deco-mini-logo {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 690px) {
  .card-container {
    padding: 9px 0 0 0;
    /* border-radius: 20px; */
  }

  .takeout-container {
    min-width: 0;
    padding: 0;
    /* border-radius: 20px; */
  }
}

.mini-logo-top {
  width: 20%;
  rotate: -14deg;
}

.mini-logo-bottom {
  width: 20%;
  rotate: 14deg;
}

@media screen and (max-width: 767px) {
  ul,
  ol {
    padding-inline-start: 0;
    /* list-style-position: inside; */
  }
}
