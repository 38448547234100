.MenuDeco {
  text-align: center;
  border: 4px double #c73d13;
  border-radius: 10rem;
  filter: drop-shadow(2px 3px 5px rgba(1, 1, 0, 0.3));
  background-color: #007bff;
}

@media screen and (max-width: 575px) {
  .MenuDeco {
    display: none;
  }
}

.MenuTitle {
  text-align: center;
  margin: 1.5rem 0 2rem 0;
}

.AssetTitle {
  width: 17%;
}

@media screen and (max-width: 992px) {
  .MenuTitle {
    margin: 3rem 0 1.5rem 0;
  }
  .AssetTitle {
    width: 180px;
  }
}

@media screen and (max-width: 575px) {
  .AssetTitle {
    width: 140px;
  }
}

.MenuTitleSpace {
  margin: 3% 0;
  padding: 0 12% 0 12%;
}

@media screen and (max-width: 575px) {
  .MenuTitleSpace {
    padding: 10% 0 10% 0;
  }
}

.MenuSpace {
  margin: 1% 0 10% 0;
}

.MenuDecoTitle {
  width: 15%;
  padding: 2%;
}

@media screen and (min-width: 1441px) {
  .MenuDecoTitle {
    max-width: 10%;
  }
}

@media screen and (max-width: 575px) {
  .MenuDecoTitle {
    max-width: 100%;
  }
}

/* .MenuSec {
  margin: 6%;
} */

.MenuHdCap {
  padding: 1% 0;
  color: #007bff;
}

.MenuHdTitele {
  padding-left: 6%;
  font-size: 80%;
  text-align: left;
  float: left;
}

.MenuHdText {
  padding-right: 4%;
  font-size: 80%;
  text-align: right;
}

.HdTextSp {
  margin-right: 7.5%;
}

.MenuCap {
  border-top: solid 1px #007bff;
  padding-top: 2%;
  color: #f3f0db;
}

.MenuCapSet {
  color: #f3f0db;
}

.MenuCapSetParty {
  text-align: right;
  color: #f3f0db;
}

.MenuTitele {
  padding-left: 4%;
  font-size: 110%;
  text-align: left;
  float: left;
}

.MenuTitleBg {
  padding: 0.5em 1em;
  margin: 1em 0;
  color: #f3f0db;
  background: #007bff;
  border-radius: 10px;
  filter: drop-shadow(2px 3px 5px rgba(1, 1, 0, 0.1));
}

.MenuTitleBgSub {
  padding: 0.5em 1rem;
  margin: 1em 0;
  color: #f3f0db;
  background: #c73d13;
  border-radius: 10px;
  filter: drop-shadow(2px 3px 5px rgba(1, 1, 0, 0.3));
}

.MenuText {
  padding-right: 4%;
  font-size: 120%;
  text-align: right;
}

.MenuSmText {
  font-size: 70%;
}

.MenuTax {
  padding-right: 4%;
  font-size: 80%;
  text-align: right;
}

.MenuTextSp {
  margin-right: 6%;
}

.MenuTextSpSingle {
  margin-right: 11%;
}

.MenuLunchTitle {
  padding-top: 5%;
  text-align: center;
}

.MenuLunchText {
  font-family: "Noto Serif JP", serif;
  color: #f3f0db;
}

.MenuLunchBottom {
  padding: 5% 20%;
}

.MenuLunchBottomText {
  padding: 10%;
  font-family: "Noto Serif JP", serif;
  background-color: #007bff;
  border-radius: 4rem;
  color: #f3f0db;
  filter: drop-shadow(2px 3px 5px rgba(1, 1, 0, 0.3));
  border: 4px double #c73d13;
}

.MenuBr {
  white-space: pre-wrap;
  font-weight: bold;
  text-shadow: (2px 3px 5px rgba(1, 1, 0, 0.3));
}

.MenuBrParty {
  white-space: pre-wrap;
}
