body {
  margin: 0;
  font-size: 16px;
  background-color: #c73d13;
  font-family: "PT Sans", sans-serif, "Noto Sans JP", sans-serif,
    "Noto Serif JP", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
